<template>
  <div>
    <div class="spaceBTD"></div>
    <nav class="navbar position-fixed top-0 navbar-light bg-white py-0 w-100 " style="z-index:1000">
    <div class="container ">
      <div class="d-flex justify-content-between align-items-center w-100 mt-3 mb-2">
        <div class="w-25 w-md-30 w-lg-30 d-flex align-items-end">
          <button class="w-35 navbar-toggler d-block d-lg-none p-0 mx-1 jump" type="button" @click.prevent="OffcanvasOpen" >
          </button>
        </div>
        <router-link to="/" class="w-lg-15 d-none d-lg-block">
          <img class="" src="@/assets/img/Icons-02.svg" alt="logo">
        </router-link>
        <router-link to="/" class="w-40 w-md-30 d-lg-none d-block">
          <img class="" src="@/assets/img/Icons-02.svg" alt="logo">
        </router-link>
        <div class="w-25 w-md-30 w-lg-30 d-flex align-items-center d-none d-lg-block">
          <ul class="list-unstyled d-flex flex-row-reverse flex-lg-row justify-content-center align-items-center  w-100 m-0 pt-2 " >
            <li class="w-10 me-1 d-none d-lg-block ">
              <!-- 免運車 -->
              <img  class="w-100 jump" src="@/assets/img/Icons-01.svg" alt="">
            </li>
            <li class="w-lg-25 w-20 fs-6 me-1 text-primary d-none d-lg-block me-lg-0 me-xl-1">
              全館免運
            </li>
          </ul>
        </div>
        <div class="w-25 w-md-30 w-lg-30 d-flex align-items-center d-lg-none d-block">
          <ul class="list-unstyled d-flex flex-lg-row justify-content-center align-items-center  w-100 m-0 pt-2 " >
            <li class="w-25 me-1 d-lg-none d-block ">
              <!-- 免運車 -->
              <img  class="w-100 jump" src="@/assets/img/Icons-01.svg" alt="">
            </li>
            <li class="w-75 fs-8 me-1 text-primary d-lg-none d-block me-lg-0 me-xl-1">
              全館免運
            </li>
          </ul>
        </div>
      </div>
    </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.spaceBTD{
  height: 72px;
  @media (min-width: 414px) {
    height: 98px;
  }
  @media (min-width: 992px) {
    height: 79px;
  }
  @media (min-width: 1024px) {
    height: 88px;
  }
  @media (min-width: 1380px) {
    height: 91px;
  }
}
</style>
