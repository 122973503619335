<template>

  <div class="container papa-right papa-left ">
    <nav class="navbar navbar-expand-lg navbar-light bg-white   w-100">
      <div class="container px-0 ">
          <ul class="collapse navbar-collapse navbar-nav justify-content-between " id="navbarNavDropdown">
            <li class="nav-item dropdown papa-effect-line w-20">
              <router-link to="/">
              </router-link>
            </li>
            <li class="nav-item dropdown me-3  papa-effect-line " v-if="!isFromUnionCarRental">
              <p class="nav-link dropdown-toggle fw-bold " href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                最新消息
              </p>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li class="papa-effect-line-down position-relative"><router-link to="/promoteeventlist" class="dropdown-item d-inline-block py-2" href="#">優惠活動</router-link></li>
                <li class="papa-effect-line-down position-relative"><router-link to="/neweventlist" class="dropdown-item d-inline-block py-2" href="#">最新消息</router-link></li>
                <li class="papa-effect-line-down position-relative"><router-link to="/seasoneventlist" class="dropdown-item d-inline-block py-2" href="#">季節限定</router-link></li>
              </ul>
            </li>
            <li class="nav-item dropdown mx-3  papa-effect-line" v-if="isFromUnionCarRental">
              <router-link to="/neweventlist" class="">
                <p class="nav-link fw-bold " href="#" >
                  最新消息
                </p>
              </router-link>
            </li>
            <li class="nav-item dropdown mx-3  papa-effect-line">
              <router-link to="/productlist" class="">
                <p class="nav-link fw-bold " href="#" >
                  全系列商品
                </p>
              </router-link>
            </li>
            <li v-if="!isExpired" class="nav-item dropdown mx-3  papa-effect-line">
              <router-link to="/double11Event" class="">
                <p class="nav-link fw-bold " href="#" >
                  1+1驚喜價
                </p>
              </router-link>
            </li>
            <li class="nav-item dropdown mx-3  papa-effect-line">
              <p class="nav-link dropdown-toggle fw-bold " href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                關於我們
              </p>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li class="papa-effect-line-down position-relative"><router-link to="/aboutus" class="dropdown-item py-2">認識Papawash</router-link></li>
                <!-- <li><router-link to="/" class="dropdown-item" href="#">品牌精神</router-link></li>
                <li><router-link to="/" class="dropdown-item" href="#">線上肌膚檢測</router-link></li> -->
                <li class="papa-effect-line-down position-relative"><router-link to="/papawashQA" class="dropdown-item py-2">Papawash Q&A</router-link></li>
                <!-- <li><router-link to="/" class="dropdown-item" href="#">洗顏方法</router-link></li> -->
              </ul>
            </li>
            <li class="nav-item dropdown mx-3 papa-effect-line">
              <p class="nav-link dropdown-toggle fw-bold  " href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                會員中心
              </p>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li class="papa-effect-line-down position-relative"><router-link to="/memberorder" class="dropdown-item py-2" >訂單資訊</router-link></li>
                <li class="papa-effect-line-down position-relative"><router-link to="/memberupdateinfo" class="dropdown-item py-2" href="#">個人帳戶管理</router-link></li>
                <!-- <li class="dropdown-item">我的優惠券</li> -->
                <!-- <li><router-link to="/" class="ps-3 dropdown-item" href="#">抵用券</router-link></li> -->
                <li class="papa-effect-line-down position-relative"><router-link to="/memberfeedback" class="dropdown-item py-2">點數</router-link></li>
                <li class="papa-effect-line-down position-relative"><router-link to="/contactus" class="dropdown-item py-2">聯絡客服</router-link></li>
                <!-- <li v-if="$store.state.isLogin" class="papa-effect-line-down position-relative">
                  <a href="#" @click.prevent="logOut" class="dropdown-item">會員登出</a>
                </li> -->
              </ul>
            </li>
            <li class="nav-item dropdown papa-effect-line w-20">
              <router-link to="/">
              </router-link>
            </li>
            <!-- <div class="d-flex ms-2 position-relative d-none d-md-flex w-20">
              <input class="form-control search" type="search" placeholder="找商品" style="">
              <a class="btn position-absolute end-0 w-20">
                <img src="../assets/img/Icons-05.svg" alt="search button" />
              </a>
            </div> -->
          </ul>
      </div>

    </nav>

  </div>

</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      isExpired: false,
      isFromUnionCarRental: false // 從租車來?
    }
  },
  methods: {
    logOut () {
      this.$swal.fire({
        title: '是否要登出?',
        allowOutsideClick: true,
        confirmButtonColor: '#003894',
        confirmButtonText: '確認',
        width: 400,
        customClass: {
          title: 'text-class',
          confirmButton: 'confirm-btn-class'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('logoutRemoveCookie')
          this.$store.commit('changeLoginState', false)
          this.$store.commit('getcartNum', 0)
          this.$router.push('/')
        }
      })
    }
  },
  mounted () {
    // ? 1+1 驚喜價活動是否已過期
    const now = moment().format('YYYY/MM/DD HH:mm:ss')
    if (moment(now, 'YYYY/MM/DD HH:mm:ss').isAfter('2022/11/30 23:59:59')) {
      this.isExpired = true
    }
    // 是否從聯邦租車頁面來
    if (this.$route.path === '/event/unionrentalcar' || sessionStorage.getItem('store')) {
      this.isFromUnionCarRental = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .dropdown-toggle::after {
    display: none;
  }
  .category-font {
    font-size: 23pt;
  }
  .dropdown-menu {
    border:none;
  }

</style>
