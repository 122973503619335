<template>
  <div class=" bottom-0 bg-primary">
    <div class="container papa-right papa-left pb-4">
      <div class=" d-flex justify-content-center w-100">
        <!-- <div class="d-none d-md-none d-lg-block w-lg-25"></div> -->
        <div class="d-flex flex-column">
          <div class="d-flex justify-content-center pt-4 pb-3">
            <div class="d-none d-md-none w-lg-30"></div>
            <div class="w-60 w-md-50 w-lg-40 p-3">
              <ul class="list-unstyled d-flex justify-content-center align-items-md-center w-100 m-0">
                <li class="w-20  d-block mx-2"><a href="https://www.facebook.com/Papawashtw"><img src="@/assets/img/Icons-16.svg" alt=""></a></li>
                <li class="w-20  d-block mx-2"><a href="https://www.instagram.com/papawashtw/"><img src="@/assets/img/Icons-17.svg" alt=""></a></li>
                <li class="w-20  d-block mx-2"><a href="https://page.line.me/agd3292i?openQrModal=true"><img src="@/assets/img/Icons-18.svg" alt=""></a></li>
              </ul>
            </div>
            <div class="d-none d-md-none w-lg-30"></div>
          </div>
          <div class="d-lg-flex justify-content-center">
            <ul class="list-unstyled d-flex align-items-md-center flex-wrap flex-md-nowrap  py-0 fs-7 fs-md-6 fs-lg-6 justify-content-center">
              <li class="papaborder-end px-md-2 text-nowrap"><router-link class="py-0 d-block text-white text-center pe-2 " to="/copyright">著作權聲明</router-link></li>
              <li class="papaborder-end px-md-2 text-nowrap"><router-link class="py-0 d-block text-white text-center px-2" to="/privacy">隱私權聲明</router-link></li>
              <li class="papaborder-end px-md-2 text-nowrap"><router-link class="py-0 d-block text-white text-center px-2 " to="/aboutus">關於我們</router-link></li>
            </ul>
            <ul class="list-unstyled d-flex align-items-md-center flex-wrap flex-md-nowrap py-0 fs-7 fs-md-6 fs-lg-6 justify-content-center">
              <li class="papaborder-end px-md-2 text-nowrap"><router-link class="py-0 d-block text-white text-center px-2" to="/antideceive">反詐騙提醒</router-link></li>
              <li class="papaborder-end px-md-2 text-nowrap"><router-link class="py-0 d-block text-white text-center px-2" to="/TWCA">TWCA</router-link></li>
              <li class="px-md-2 text-nowrap"><router-link class="py-0 d-block text-white text-center ps-2" to="/returnexchangepolicy">退換貨須知</router-link></li>
            </ul>
          </div>
          <div class="py-1 py-md-2">
             <p class="text-center text-white fs-7 fs-md-5 fs-lg-5 fw-bold">
              Papawash客服專線電話:0809-080-038 週一至週五 9:00-12:00、13:30-18:00(國定例假日除外)
            </p>
          </div>
          <div class="pt-1 pt-md-3 pb-0 pb-md-2">
           <p class="text-center text-white fs-7 fs-md-6 fs-lg-6">
             聯邦網通科技股份有限公司(統一編號:16614905) / 版權所有 / 轉載必究
            </p>
          </div>
          <div>
            <p class="text-center text-white fs-9 fs-md-6 fs-lg-8 px-1 px-md-0" style="text-align:justify;">
              Copyright©2015 Union Information Technology Corp. All Rights Reserved.
            </p>
          </div>
        </div>
        <!-- <div class="d-none d-md-none d-lg-block w-lg-25"></div> -->
      </div>
    </div>
  </div>
</template>
<script>

export default {
}
</script>

<style lang="scss">
@import '@/assets/scss/all';

.bg-primary {
  background-color: $primary;
}
</style>
